import Vue from 'vue'
import Vuex from 'vuex'
import router from './router/index'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  cart: '0',
  user: null
}

const getters = {
  cart: state => state.cart
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  toggle (state, variable) {
    state[variable] = !state[variable]
  },
  SET_CART (state) {
    if (window.localStorage.getItem('cart')) {
      state.cart = JSON.parse(window.localStorage.getItem('cart'))
    } else {
      state.cart = 0
    }
  },
  fetchUser (state) {
    var that = this
    // if (localStorage.getItem("api_token")) {
      console.log('router')
      console.log(router)
    // setTimeout(function () {
    //   console.log(router)
    //   if (router.app._route.path.substr(0, 14) !== '/register_user' && router.app._route.path.substr(0,16) != '/forgot_password' && router.app._route.path.substr(0,13) != '/new_password') {
        axios.get('https://server.imperial3pl.com/api/fetchUser?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          console.log('fetch user')
            console.log(response)
            state.user = response.data
        }).catch(function (error) {
          console.log(error)
          // router.push('/login')
          console.log('path')
          console.log(router.currentRoute.path)
          
            console.log('running')
              router.push('/login')
        });
    //   }
    // }, 1000)
    // }
  },
  refreshUser (state) {
    if (router.app._route.path.substr(0, 14) !== '/register_user' && router.app._route.path.substr(0,16) != '/forgot_password' && router.app._route.path.substr(0,13) != '/new_password') {
      setTimeout(function () {
        axios.post('http://localhost:8000/api/refresh?token=' + localStorage.getItem('api_token'))
        .then(function (response) {
          console.log('refresh')
          console.log(response)
        })
      }, 1000)
    }
  },
  axios_error (state, variable) {
    console.log(variable)
    Swal.fire({
      title: 'Error!',
      text: 'It looks like something happened. Please contact acoleman@paifashion.com if the error keeps happening.',
      icon: 'warning',
      confirmButtonText: 'Okay',
    })
  },
  getUser() {
    return state.user
  }
}

const actions = {
  setCart ({ commit }, obj) {
    commit('SET_CART')
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})