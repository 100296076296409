export default {
    "en": {
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": "The password is incorrect.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "days": {
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thrusday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday"
        },
        "dashboard": {
            "action": "Action",
            "another_action": "Another action",
            "something_else_here": "Something else here",
            "disabled_action": "Disabled action",
            "members_online": "Members online",
            "Traffic": "Traffic",
            "september": "September",
            "day": "Day",
            "month": "Month",
            "year": "Year",
            "users": "Users",
            "visits": "Visits",
            "unique": "Unique",
            "pageviews": "Pageviews",
            "new_users": "New Users",
            "bounce_rate": "Bounce Rate",
            "friends": "friends",
            "feeds": "feeds",
            "followers": "followers",
            "tweets": "tweets",
            "contacts": "contacts",
            "traffic_&_sales": "Traffic & Sales",
            "new_clients": "New Clients",
            "recurring_clients": "Recurring Clients",
            "organic": "Organic",
            "male": "Male",
            "female": "Female",
            "organic_search": "Organic Search",
            "user": "User",
            "country": "Country",
            "usage": "Usage",
            "payment_method": "Payment Method",
            "activity": "Activity",
            "new": "New",
            "recurring": "Recuring",
            "registered": "Registered",
            "last_login": "Last login",
            "time": {
                "sec_ago": "sec ago",
                "minutes_ago": "minutes ago",
                "hour_ago": "hour ago",
                "last_month": "Last month",
                "last_week": "Last week",
                "yesterday": "Yesterday"
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "coreuiforms": {
            "notes": {
                "note": "Note",
                "notes": "Notes",
                "create_note": "Create Note",
                "add_note": "Add Note",
                "author": "Author",
                "title": "Title",
                "content": "Content",
                "applies_to_date": "Applies to date",
                "status": "Status",
                "note_type": "Note type"
            },
            "users": {
                "user": "User",
                "users": "Users",
                "username": "Username",
                "email": "E-mail",
                "roles": "Roles",
                "email_verified_at": "Email verified at"
            },
            "menu": {
                "menu_list": "Menu list",
                "add_new_menu": "Add new menu",
                "name": "Name",
                "create_menu": "Create menu",
                "menu_elements": "Menu Elements",
                "add_new_menu_element": "Add new menu element",
                "change_menu": "Change menu",
                "type": "Type",
                "href": "href",
                "sequence": "Sequence",
                "show_menu_element": "Show menu element",
                "menu": "Menu",
                "user_roles": "User Roles",
                "translations": "Translations",
                "dropdown_parent": "Dropdown parent",
                "icon": "Icon",
                "edit_menu_element": "Edit menu element",
                "other": "Other",
                "find_icon_class_in": "Find icon class in",
                "coreui_icons_docs": "CoreUI icons documentation",
                "create_menu_element": "Create menu element"
            },
            "roles": {
                "menu_roles": "Menu roles",
                "add_new_role": "Add new role",
                "name": "Name",
                "hierarchy": "Hierarchy",
                "created_at": "Created at",
                "updated_at": "Updated at",
                "create_new_role": "Create new role",
                "edit_role": "Edit role",
                "show_role": "Show role"
            },
            "media": {
                "media": "Media",
                "back": "Back",
                "new_folder": "New folder",
                "new_file": "New file",
                "rename": "Rename",
                "move": "Move",
                "delete": "Delete",
                "open": "Open",
                "copy": "Copy",
                "cropp": "Cropp",
                "move_folder": "Move folder",
                "move_up": "Move up",
                "move_file": "Move file",
                "rename_file": "Rename file",
                "rename_folder": "Rename folder",
                "file_info": "File info",
                "name": "Name",
                "real_name": "Real Name",
                "url": "URL",
                "mime_type": "mime type",
                "size": "Size",
                "created_at": "Created at",
                "updated_at": "Updated at",
                "delete_file": "Delete file",
                "are_you_sure": "Are you sure?",
                "delete_folder": "Delete folder",
                "cropp_image": "Cropp image",
                "delete_folder_warn": "If you delete a folder, all subfolders and files will also be deleted."
            },
            "bread": {
                "add_new_bread": "Add new BREAD",
                "go_to_resource": "Go to resource",
                "create_bread": "Create BREAD",
                "table_name_db": "Table name in database",
                "form_name": "Form name",
                "pagination": "Records on one page of table",
                "enable_show": "Enable Show button in table",
                "enable_edit": "Enable Edit button in table",
                "enable_add": "Enable Add button in table",
                "enable_delete": "Enable Delete button in table",
                "assign_to_roles": "Assign to roles",
                "visible_name": "Visible name",
                "field_type": "Field type",
                "relation_table": "Optional relation table name",
                "relation_column": "Optional column name in relation table - to print",
                "show_bread": "Show BREAD",
                "edit_bread": "Edit BREAD",
                "delete_bread": "Delete BREAD"
            },
            "resource": {
                "add_new": "Add new",
                "add": "Add",
                "show": "Show"
            },
            "select": "Select",
            "view": "View",
            "edit": "Edit",
            "delete": "Delete",
            "return": "Return",
            "save": "Save",
            "cancel": "Cancel",
            "are_you_sure": "Are you sure?"
        }
    },
    "pl": {
        "days": {
            "monday": "Poniedziałek",
            "tuesday": "Wtorek",
            "wednesday": "Środa",
            "thrusday": "Czwartek",
            "friday": "Piątek",
            "saturday": "Sobota",
            "sunday": "Niedziela"
        },
        "dashboard": {
            "action": "Akcja",
            "another_action": "Kolejna akcja",
            "something_else_here": "Tutaj coś jeszcze",
            "disabled_action": "Zablokowana akcja",
            "members_online": "Członków online",
            "Traffic": "Ruch",
            "september": "Wrzesień",
            "day": "Dzień",
            "month": "Miesiąc",
            "year": "Rok",
            "users": "Użytkowników",
            "visits": "Wizyt",
            "unique": "Unikatowych",
            "pageviews": "Odświerzeń",
            "new_users": "Nowi użytkownicy",
            "bounce_rate": "Wskaźnik odrzuceń",
            "friends": "przyjaciół",
            "feeds": "feeds",
            "followers": "podążających",
            "tweets": "tweetów",
            "contacts": "kontaktów",
            "traffic_&_sales": "Ruch i Sprzedaż",
            "new_clients": "Nowi klienci",
            "recurring_clients": "Powtarzający się klienci",
            "organic": "Organiczny",
            "male": "Mężczyźni",
            "female": "Kobiety",
            "organic_search": "Wyszukiwanie organiczne",
            "user": "Użytkownik",
            "country": "Kraj",
            "usage": "Zużycie",
            "payment_method": "Metoda płatności",
            "activity": "Aktywność",
            "new": "Nowy",
            "recurring": "Powracający",
            "registered": "Zarejestrowany",
            "last_login": "Ostatnie logowanie",
            "time": {
                "sec_ago": "sekund temu",
                "minutes_ago": "minut temu",
                "hour_ago": "godzinę temu",
                "last_month": "Ostatni miesiąc",
                "last_week": "Ostatni tydzień",
                "yesterday": "Wczoraj"
            }
        },
        "coreuiforms": {
            "notes": {
                "note": "Notatka",
                "notes": "Notatki",
                "create_note": "Utwórz notatkę",
                "add_note": "Dodaj notatkę",
                "author": "Autor",
                "title": "Tytuł",
                "content": "Zawartość",
                "applies_to_date": "Dotyczy daty",
                "status": "Status",
                "note_type": "Typ notatki"
            },
            "users": {
                "user": "Użytkownik",
                "users": "Użytkownicy",
                "username": "Nazwa użytkownika",
                "email": "E-mail",
                "roles": "Role",
                "email_verified_at": "Data weryfikacji E-mail"
            },
            "menu": {
                "menu_list": "Lista menu",
                "add_new_menu": "Dodaj nowe menu",
                "name": "Nazwa",
                "create_menu": "Utwórz menu",
                "menu_elements": "Elementy menu",
                "add_new_menu_element": "Dodaj nowy element menu",
                "change_menu": "Zmień menu",
                "type": "Typ",
                "href": "href",
                "sequence": "Kolejność",
                "show_menu_element": "Pokaż element menu",
                "menu": "Menu",
                "user_roles": "Role użytkowników",
                "translations": "Tłumaczenia",
                "dropdown_parent": "Rodzic dropdown",
                "icon": "Ikona",
                "edit_menu_element": "Edytuj element menu",
                "other": "Inne",
                "find_icon_class_in": "Znajdź klasę ikony w",
                "coreui_icons_docs": "Dokumentacji ikon CoreUI",
                "create_menu_element": "Utwórz element menu"
            },
            "roles": {
                "menu_roles": "Role menu",
                "add_new_role": "Dodaj nową role",
                "name": "Nazwa",
                "hierarchy": "Hierarchia",
                "created_at": "Data utworzenia",
                "updated_at": "Data aktualizacji",
                "create_new_role": "Utwórz nową rolę",
                "edit_role": "Edytuj role",
                "show_role": "Pokaż role"
            },
            "media": {
                "media": "Media",
                "back": "Powrót",
                "new_folder": "Nowy folder",
                "new_file": "Nowy plik",
                "rename": "Zmień nazwę",
                "move": "Przemieść",
                "delete": "Usuń",
                "open": "Otwórz",
                "copy": "Kopiuj",
                "cropp": "Przytnij",
                "move_folder": "Przemieść folder",
                "move_up": "Przemieść w górę",
                "move_file": "Przemieść plik",
                "rename_file": "Zmień nazwę pliku",
                "rename_folder": "Zmień nazwę folderu",
                "file_info": "Informacje o pliku",
                "name": "Nazwa",
                "real_name": "Prawdziwa nazwa",
                "url": "URL",
                "mime_type": "mime type",
                "size": "Rozmiar",
                "created_at": "Data utworzenia",
                "updated_at": "Data aktualizacji",
                "delete_file": "Usuń plik",
                "are_you_sure": "Czy na pewno?",
                "delete_folder": "Usuń folder",
                "cropp_image": "Przytnij obraz",
                "delete_folder_warn": "Jeżeli usuniesz folder, wszystkie podfoldery i zwarte w nich pliki zostaną również usunięte."
            },
            "bread": {
                "add_new_bread": "Dodaj nowy BREAD",
                "go_to_resource": "Przejdź do zasobu",
                "create_bread": "Utwórz BREAD",
                "table_name_db": "Nazwa tabeli w bazie danych",
                "form_name": "Nazwa formularza",
                "pagination": "Rekordów na jednej stronie",
                "enable_show": "Dołącz przycisk Show do formularza",
                "enable_edit": "Dołącz przycisk Edit do formularza",
                "enable_add": "Dołącz przycisk Add do formularza",
                "enable_delete": "Dołącz przycisk Delete do formularza",
                "assign_to_roles": "Przypisz do roli",
                "visible_name": "Widoczna nazwa",
                "field_type": "Typ pola",
                "relation_table": "Opcjonalna zawa relacyjnej tabeli",
                "relation_column": "Opcjonalna nazwa kolumny do wyświetlenia z relacyjnej tabeli",
                "show_bread": "Pokaż BREAD",
                "edit_bread": "Edytuj BREAD",
                "delete_bread": "Usuń BREAD"
            },
            "resource": {
                "add_new": "Dodaj",
                "add": "Dodaj",
                "show": "Pokaż"
            },
            "select": "Wybierz",
            "view": "Podgląd",
            "edit": "Edycja",
            "delete": "Usuń",
            "return": "Powrót",
            "save": "Zapisz",
            "cancel": "Anuluj",
            "are_you_sure": "Czy jesteś pewny?"
        }
    }
}
