<template>
    <div style="width:100%;float:left;text-align:center;font-size:30px;">
        <CSpinner color="success" size="sm"/>
    </div>
</template>
<script>
var methods = {}

export default {
    name: "SmallLoader",
    data: function () {
    	return {

    	}
    },
    methods: methods,
    mounted: function() {

    },
    destroyed: function() {

    }
}
</script>
