import Vue from 'vue'
import Router from 'vue-router'
import store from './../store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')


//Mine
const InventoryPage = () => import('@/views/inventory/inventory_page')
const InventoryPageLocation = () => import('@/views/inventory/inventory_page_location')
const MissingImages = () => import('@/views/inventory/missing_images')
const Locations = () => import('@/views/inventory/locations')
const EditLocationStyles = () => import('@/views/inventory/edit_location_styles')
const InventoryPageLogo = () => import('@/views/inventory/inventory_page_logo')
const Checkout = () => import('@/views/orders/checkout')
const OrderSummary = () => import('@/views/orders/order_summary')
const OrderDisplay = () => import('@/views/orders/order_display')
const OrdersTable = () => import('@/views/orders/orders_table')
const MyOrders = () => import('@/views/orders/my_orders')
const ShipToTable = () => import('@/views/admin/ship_to_table')

const LogoGrouping = () => import('@/views/inventory/logo_grouping')

// // Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const ForgotPassword = () => import('@/views/pages/forgot_password')
const NewPassword = () => import('@/views/pages/new_password')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
const EditUser = () => import('@/views/users/EditUser')

//Roles
const Roles = () => import('@/views/roles/Roles')
const Role = () => import('@/views/roles/Role')
const EditRole = () => import('@/views/roles/EditRole')
const CreateRole = () => import('@/views/roles/CreateRole')

const Menus       = () => import('@/views/menu/MenuIndex')
const CreateMenu  = () => import('@/views/menu/CreateMenu')
const EditMenu    = () => import('@/views/menu/EditMenu')
const DeleteMenu  = () => import('@/views/menu/DeleteMenu')

const MenuElements = () => import('@/views/menuElements/ElementsIndex')
const CreateMenuElement = () => import('@/views/menuElements/CreateMenuElement')
const EditMenuElement = () => import('@/views/menuElements/EditMenuElement')
const ShowMenuElement = () => import('@/views/menuElements/ShowMenuElement')
const DeleteMenuElement = () => import('@/views/menuElements/DeleteMenuElement')

const Media = () => import('@/views/media/Media')

Vue.use(Router)

let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})


router.beforeEach((to, from, next) => {
  let roles = localStorage.getItem("roles");
  if(roles != null){
    roles = roles.split(',')
  }
  console.log('roles')
  console.log(roles)
  console.log(to)
  console.log(store.state)
  // if (store.state.user === null && to.path !== '/login') {
  //   next({
  //     path: '/login',
  //     params: { nextUrl: to.fullPath }
  //   })
  // }
  // if (to.matched.some(({path}) => path === '/')) {
  //   if (roles.indexOf('admin')) {
      
  //   }
  // }
  if(to.matched.some(record => record.meta.requiresAdmin)) {
    if(roles != null && roles.indexOf('admin') >= 0 ){
      next()
    }else{
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }else if(to.matched.some(record => record.meta.requiresCsr)) {
    if(roles != null && roles.indexOf('user') >= 0 ){
      next()
    }else{
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }else if(to.matched.some(record => record.meta.requiresCustomer)) {
    if(roles != null && roles.indexOf('user') >= 0 ){
      next()
    }else{
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }else if(to.matched.some(record => record.meta.requiresUser)) {
    if(roles != null && roles.indexOf('user') >= 0 ){
      next()
    }else{
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }else if(to.matched.some(record => record.meta.requiresLogin)) {
    if (roles != null) {
      next()
    } else {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    }
  }else{
    next()
  }
})

export default router

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresLogin: true
      },
      children: [
        // {
        //   path: 'media',
        //   name: 'Media',
        //   component: Media,
        //   meta:{
        //     requiresAdmin: true
        //   }
        // },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          // meta: {
          //   requiresLogin: true
          // }
        },
        {
          path: 'inventory_page',
          name: 'Inventory Page',
          component: InventoryPage
        },
        {
          path: 'inventory_page_location',
          name: 'Inventory Page Location',
          component: InventoryPageLocation
        },
        {
          path: 'locations',
          name: 'Locations',
          component: Locations
        },
        {
          path: 'locations/:id',
          name: 'Edit Location Styles',
          component: EditLocationStyles,
          props: true
        },
        {
          path: 'inventory_page_logo',
          name: 'Inventory Page Logo',
          component: InventoryPageLogo
        },
        {
          path: 'missing_images',
          name: 'Missing Images',
          component: MissingImages
        },
        {
          path: 'checkout',
          name: 'Checkout Page',
          component: Checkout
        },
        {
          path: 'order_summary/:token',
          name: 'Order Summary',
          component: OrderSummary,
          props: true
        },
        {
          path: 'order_display/:token',
          name: 'Order Display',
          component: OrderDisplay,
          props: true
        },
        {
          path: 'orders_table',
          name: 'Orders Table',
          component: OrdersTable
        },
        {
          path: 'ship_to_table',
          name: 'Ship To Table',
          component: ShipToTable,
          requiresAdmin: true
        },
        {
          path: 'my_orders',
          name: 'My Orders',
          component: MyOrders
        },
        {
          path: 'logo_grouping',
          name: 'Logo Grouping',
          component: LogoGrouping
        },
        {
          path: 'menu',
          meta: { label: 'Menu'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Menus,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: 'create',
              meta: { label: 'Create Menu' },
              name: 'CreateMenu',
              component: CreateMenu,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit Menu' },
              name: 'EditMenu',
              component: EditMenu,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':id/delete',
              meta: { label: 'Delete Menu' },
              name: 'DeleteMenu',
              component: DeleteMenu,
              meta:{
                requiresAdmin: true
              }
            },
          ]
        },
        {
          path: 'menuelement',
          meta: { label: 'MenuElement'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: ':menu/menuelement',
              component: MenuElements,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':menu/menuelement/create',
              meta: { label: 'Create Menu Element' },
              name: 'Create Menu Element',
              component: CreateMenuElement,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':menu/menuelement/:id',
              meta: { label: 'Menu Element Details'},
              name: 'Menu Element',
              component: ShowMenuElement,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':menu/menuelement/:id/edit',
              meta: { label: 'Edit Menu Element' },
              name: 'Edit Menu Element',
              component: EditMenuElement,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':menu/menuelement/:id/delete',
              meta: { label: 'Delete Menu Element' },
              name: 'Delete Menu Element',
              component: DeleteMenuElement,
              meta:{
                requiresAdmin: true
              }
            },
          ]
        },
        {
          path: 'users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Users,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':id',
              meta: { label: 'User Details'},
              name: 'User',
              component: User,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit User' },
              name: 'EditUser',
              component: EditUser,
              meta:{
                requiresAdmin: true
              }
            },
          ]
        },
        {
          path: 'roles',
          meta: { label: 'Roles'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Roles,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: 'create',
              meta: { label: 'Create Role' },
              name: 'Create Note',
              component: CreateRole,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':id',
              meta: { label: 'Role Details'},
              name: 'Role',
              component: Role,
              meta:{
                requiresAdmin: true
              }
            },
            {
              path: ':id/edit',
              meta: { label: 'Edit Role' },
              name: 'Edit Role',
              component: EditRole,
              meta:{
                requiresAdmin: true
              }
            },
          ]
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
      ]
    },
    {
      path: '/',
      redirect: '/login',
      name: 'Auth',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register_user/:token',
          name: 'Register User',
          component: Register,
          props: true
        },
        {
          path: 'forgot_password',
          name: 'Forgot Password',
          component: ForgotPassword
        },
        {
          path: 'new_password/:token',
          name: 'New Password',
          component: NewPassword,
          props: true
        }
      ]
    },
    {
      path: '*',
      name: '404',
      component: Page404
    }
  ]
}

