<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  mounted: function () {
  	this.$store.dispatch('setCart')
  	var that = this
  	setTimeout(function () {
  		if (that.$router.app._route.path.substr(0, 14) !== '/register_user' && that.$router.app._route.path.substr(0,16) != '/forgot_password' && that.$router.app._route.path.substr(0,13) != '/new_password')
		  	axios.post('https://server.imperial3pl.com/api/refresh?token=' + localStorage.getItem('api_token'))
		    .then(function (response) {
		      console.log('refresh')
		      console.log(response)
		      localStorage.setItem("api_token", response.data.access_token);
          localStorage.setItem('roles', response.data.roles);
          setTimeout(function () {
		      	that.$store.commit('fetchUser')
		      }, 500)
		    }).catch(function (e) {
		    	console.log('e')
		    })
	}, 1000)
  		// this.$store.commit('fetchUser')
  	// this.$store.commit('refreshUser')
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
