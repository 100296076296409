import 'core-js/stable'
window.axios = require('axios');
import Vue from 'vue'
import CoreuiVue from '@coreui/vue'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import vuexI18n from 'vuex-i18n';
import Locales from './locale/vue-i18n-locales.generated.js';
import axios from 'axios'

import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import SmallLoader from './views/components/small_loader'
Vue.component('small-loader', SmallLoader)

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log('main axios err')
  console.log(error.response.status)
  if (error.response.status === 401) {
    console.log(router)
      // router.push('/login')
      
      setTimeout(function () {
        console.log('running')
        // if (router.currentRoute.path.substr(0, 14) !== '/register_user' && router.currentRoute.path.substr(0,16) != '/forgot_password' && router.currentRoute.path(0,13) != '/new_password') {
          // router.push('/login')
          // params: { nextUrl: to.fullPath }
          console.log(router)
          router.push({name: 'Login', params: {nextUrl: router.history.current.fullPath}})
        // }
      }, 500)
  }
})

Vue.prototype.$apiAdress = 'https://server.imperial3pl.com'
// Vue.prototype.$apiAdress = 'http://localhost:8000'
Vue.use(CoreuiVue)
Vue.use(vuexI18n.plugin, store);
Vue.i18n.add('en', Locales.en);
Vue.i18n.add('pl', Locales.pl);
let locale = 'en'
if(typeof localStorage.locale !== 'undefined'){
  locale = localStorage.getItem("locale")
}
Vue.i18n.set(locale);

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App
  }
})

